<template>
  <div class="seniorList">
    <div>
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="140px"
        size="small"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="机构名称：">
              <el-input
                v-model="searchForm.senior_name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <div>
            <el-button type="primary" size="small" @click="serchData"
              >查询</el-button
            >
          </div>
        </el-row>
      </el-form>
    </div>
    <!-- <div class="derive">
      <el-button
        size="small"
        type="primary"
        v-if="managerType != 0 || getAuth('createSenior')"seniorOperate
        @click="createSenior()"
        >添加</el-button
      >
    </div> -->
    <div>
      <base-table
        :columns="seniorCol"
        :data="tableData"
        :pagination="searchForm"
        :total="total"
        @getData="initData"
        :loading="loading"
        :isPaginationShow="true"
        wrapperHeight="calc(100% - 45px)"
      >
        <el-table-column slot="entity_num" label="主体数量" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="skipEnitiy(scope.row)"
              >{{ scope.row.entity_num }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column slot="business_num" label="商家数量" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="skipBusiness(scope.row)"
              >{{ scope.row.business_num }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          slot="operation"
          label="操作"
          align="center"
          width="180"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-if="managerType != 0 || getAuth('seniorOperate')"
              @click="editSenior(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="managerType != 0 || getAuth('seniorOperate')"
              @click="delSenior(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </base-table>
    </div>
    <seniorModal ref="seniorModal" />
  </div>
</template>

<script>
import columns from "./columns";
import seniorModal from "./components/senior-modal.vue";
import { listSenior, statusSenior } from "@/api/senior.js";
import { getAuth } from "@/utils/index.js";
export default {
  name: "seniorList",
  components: { seniorModal },
  data() {
    return {
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      ...columns,
      tableData: [],
      total: 0,
      loading: false,
      searchForm: {
        page: 1,
        per_page: 10,
        senior_name: "",
      },
    };
  },

  mounted() {
    this.initData();
  },

  methods: {
    serchData(){
      this.searchForm.page = 1
      this.searchForm.per_page = 10
      this.initData()
    },
    async initData() {
      const { code, data } = await listSenior(this.searchForm);
      if (code == 200) {
        this.tableData = data.data;
        this.total = data.total;
      }
    },
    createSenior() {
      this.$refs.seniorModal.isShow('add','');
    },
    editSenior(row) {
      this.$refs.seniorModal.isShow('edit',row);
    },
    delSenior(row) {
      this.$confirm("是否删除当前机构？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const parmes = {
            senior_id: row.senior_id,
            status: 2,
          };
          const { code } = await statusSenior(parmes);
          if (code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.initData();
          }
        })
        .catch(() => {});
    },
    skipEnitiy(row) {
      this.$router.push({
        path: "/business/entityList",
        query: {
          senior_id: row.senior_id,
          senior_name: row.senior_name,
        },
      });
    },
    skipBusiness(row) {
      this.$router.push({
        path: "/business/businessList",
        query: {
          senior_id: row.senior_id,
          senior_name: row.senior_name,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.seniorList {
  .derive {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}
</style>