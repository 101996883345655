export const seniorCol = [
  {
    label: '机构名称',
    prop: 'senior_name',
    align: 'center'
  },
  {
    label: '主体数量',
    slot:'entity_num'
  },
  {
    label: '商家数量',
    slot:'business_num'
  },
  {
    label: '创建人',
    prop: 'create_user_name',
    align: 'center'
  },
  {
    label: '创建时间',
    prop: 'create_time',
    align: 'center'
  },
  {
    label: '更新人',
    prop:'update_user_name',
    align: 'center'
  },
  {
    label: '更新时间',
    prop:'update_time',
    align: 'center'
  },
  {
    label: '操作',
    slot:'operation'
  },
]

export default {
  seniorCol
}